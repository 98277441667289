<template>
    <v-container
        fluid
        class="overflow-y-auto my-4"
        :style="`height:${this.height}px`"
    >
        <v-data-table
            hide-default-footer
            :headers="headers"
            color="grey lighten-3"
            :items="tasks"
            :loading="loading"
            class="elevation-1"
            style="width: 100%"
        >
            <template v-slot:top>
                <v-toolbar flat style="background-color: #eeeeee !important">
                    <v-toolbar-title>TASKS</v-toolbar-title>
                </v-toolbar>
            </template>
            <!-- ITEMS -->
            <template v-slot:[`item.expectedDate`]="{ item }">
                <div class="d-flex" style="width: 90px">
                    <span class="mt-0 text-body-1">{{
                        formatDate(item.expectedDate)
                    }}</span>
                </div>
            </template>
            <template v-slot:[`item.description`]="{ item }">
                <div class="d-flex" style="width: 700px">
                    <span class="mt-0 text-body-1">{{ item.description }}</span>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center">
                    <v-chip :color="colors[item.status]" dark>
                        <span
                            class="mt-0 text-body-1"
                            style="text-align: center !important"
                            >{{ item.status }}</span
                        >
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.assignedTo`]="{ item }">
                <div class="d-flex justify-center">
                    <v-avatar size="28">
                        <v-img
                            v-if="item.imageOfTheAssignee"
                            :src="item.imageOfTheAssignee"
                        />
                        <v-icon x-large color="#707070" v-else
                            >mdi-account-circle</v-icon
                        >
                    </v-avatar>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-menu rounded offset-y>
                        <template v-slot:activator="{ attrs, on }">
                            <v-btn
                                fab
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="grey lighten-3"
                                depressed
                                class="mx-1"
                            >
                                <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click.stop="openRestoreDialog = true">
                                <v-dialog
                                    v-model="openRestoreDialog"
                                    persistent
                                    max-width="400px"
                                    :retain-focus="false"
                                >
                                    <v-card
                                        :loading="loading"
                                        v-if="openRestoreDialog"
                                    >
                                        <v-card-title class="text-h5"
                                            >Restore Task</v-card-title
                                        >
                                        <v-card-text>
                                            Are you sure you want to restore
                                            this task?
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn
                                                text
                                                color="secondary"
                                                @click="
                                                    openRestoreDialog = false
                                                "
                                            >
                                                Close
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                @click="restoreTasks(item)"
                                                text
                                                color="error"
                                                :loading="loading"
                                            >
                                                Restore
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-list-item-title>
                                    Restore
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click.stop="openDeleteDialog = true">
                                <v-dialog
                                    v-model="openDeleteDialog"
                                    persistent
                                    max-width="400px"
                                    :retain-focus="false"
                                >
                                    <v-card :loading="loading">
                                        <v-card-title class="text-h5"
                                            >Delete Task</v-card-title
                                        >
                                        <v-card-text>
                                            Are you sure you want to delete this
                                            task?
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn
                                                text
                                                color="secondary"
                                                @click="
                                                    openDeleteDialog = false
                                                "
                                            >
                                                Close
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                @click="deleteTask(item)"
                                                text
                                                color="error"
                                                :loading="loading"
                                            >
                                                Delete
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-list-item-title>
                                    Delete
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import moment from 'moment'
import { loadImage } from '@/helpers/imageHandler'

export default {
    name: 'ArchivedTasks',
    data: () => ({
        loading: false,
        height: 0,
        headers: [
            {
                text: 'EXPECTED DATE',
                value: 'expectedDate',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                sortable: false,
            },
            {
                text: 'STATUS',
                value: 'status',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ASSIGNED',
                value: 'assignedTo',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        tasks: [],
        users: [],
        companyId: JSON.parse(localStorage.getItem('company')),
        folderUsersPictures: 'users_pictures',
        colors: {
            onHold: '#EDC43B',
            toDo: '#3277D1',
            inProgress: '#FF9800',
            done: '#27BC4F',
        },
        openDeleteDialog: false,
        openRestoreDialog: false,
        userId: JSON.parse(localStorage.getItem('userId')),
    }),
    async mounted() {
        try {
            this.loading = true
            this.onResize()
            // get users
            const {
                data: { users },
            } = await API.getUsers()
            this.users = users
            // get tasks
            this.tasks = await API.getTasks(true)
            for (let task of this.tasks) {
                task.imageOfTheAssignee = await this.loadUserImage(
                    task.assignedTo
                )
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async deleteTask(task) {
            try {
                this.loading = true
                await API.deleteTask({
                    id: task.id,
                    assignedTo: task.assignedTo,
                })
                const index = this.tasks.findIndex(t => t.id == task.id)
                if (index >= 0) {
                    this.tasks.splice(index, 1)
                }
                // retrieve user
                const {
                    data: { user },
                } = await API.getMyInfo()
                if (user.onHold.includes(task.id)) {
                    const onHold = user.onHold.filter(t => t != task.id)
                    await API.updateUser(this.userId, { onHold })
                } else if (user.toDo.includes(task.id)) {
                    const toDo = user.toDo.filter(t => t != task.id)
                    await API.updateUser(this.userId, { toDo })
                } else if (user.inProgress.includes(task.id)) {
                    const inProgress = user.inProgress.filter(t => t != task.id)
                    await API.updateUser(this.userId, { inProgress })
                } else if (user.done.includes(task.id)) {
                    const done = user.done.filter(t => t != task.id)
                    await API.updateUser(this.userId, { done })
                }
                this.openDeleteDialog = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async restoreTasks(task) {
            try {
                this.loading = true
                await API.updateTask(task.id, {
                    archive: false,
                    assignedTo: task.assignedTo,
                    notificationId: 'rCJFinVt7UiC6uTysz2i',
                })
                const index = this.tasks.findIndex(t => t.id == task.id)
                if (index >= 0) {
                    this.tasks.splice(index, 1)
                }
                this.openRestoreDialog = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        onResize() {
            this.height = window.innerHeight - 112
        },
        formatDate(date) {
            return moment.unix(date._seconds).format('YYYY-MM-DD')
        },
        async loadUserImage(userId) {
            try {
                const user = this.users.find(user => user.id === userId)
                if (user) {
                    if (user.picture) {
                        const path = `${this.companyId}/${this.folderUsersPictures}`
                        user.avatar = await loadImage(path, user.picture)
                    }
                    return user.avatar
                }
            } catch (error) {
                this.setItemsError(error.message)
            }
        },
    },
}
</script>

<style></style>
